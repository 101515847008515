import React from "react";
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";

const Commitments = ({ data: { hero, intro, commitments } }) => {

    const renderIcons = (title) => {
        switch(title) {
            case "Quality":
                return <img src="/images/commitments/logos/certs.png" />
            case "Safety":
                return ( <>
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/iso.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/iso.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/iso.png" />
                </>
                )
            case "Sustainability":
                return ( <>
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/usgbc.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/leed.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/iso.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/ohas.png" />
                    <img className="inline-block mr-2 h-12" src="/images/commitments/logos/iaf.png" />
                </>
                )
        }
    }

return (

    <Layout hero={{smallerTextHeading:false, ...hero}}>
    <div className="commitment-anchor" id="corporategovernance" />
    <div className="container mx-auto mb-16 sm:mb-32">
        <div className="md:flex items-center justify-between">
            <div className="flex-1 px-4 mb-4 md:mb-0 self-start">
                <Img className="lg:h-full mb-4" alt="" fluid={intro.introductoryImage.fluid} />
            </div>
            <div className="flex-1 px-4">
                <div className="mb-6">
                    <h2 className="text-3xl sm:text-5xl font-bold dark-blue leading-tight font-aktiv">Corporate Governance</h2>
                </div>
                <p className="soft-gray mb-4">We strive to operate our businesses on principles that ensure the safety, security and health of our employees, customer partners, and the communities we work in.</p>
                
                <p className="soft-gray mb-4">Our responsibility as a corporate citizen is to ensure the profitability and success of our company in order to impact society in the most positive way possible.</p>
                
                <svg className="h-4 w-4 inline-block mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>

                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <Link to={`/code-of-ethics`}>View our code of ethics</Link>
                </div>
                
                <h2 className="text-2xl font-bold dark-blue leading-tight mt-16">Partnerships</h2>
                
                <p className="mt-4 soft-gray">Our word is at the base of every endeavour we embark on. Through trust, and transparent partnerhips with individuals, organizations, and governments, we are commited to delivering on what say we will do. Our clients expect a lot. We wouldn't have it any other way.</p>
                <div className="inline-block uppercase pt-6 border-b-2 text-xs border-mane-yellow text-gray-800 font-bold font-aktivcd">
                    <Link to="/who-we-are">Learn more about us</Link>
                </div>
                
            </div>
        </div>
    </div>

    {commitments.commitments.map((node, i) => {
        if (i % 2 === 0) {
            return (
                <div className="bg-gray border-t border-b py-8 sm:py-16 mb:8 sm:mb-16">
                        <div className="commitment-anchor" id={node.title.replace(/\s+/g, '').toLowerCase()}/>
                        <div className="container mx-auto sm:flex">

                            <div className="flex-1 order-first px-4">
                                <div className="mb-6">
                                    <h2 className="sm:w-3/4 text-3xl sm:text-5xl font-bold dark-blue leading-tight font-aktiv">{node.title}</h2>
                                    <div className="soft-gray my-4 nested-html"
                                         dangerouslySetInnerHTML={{ __html: node.description.childMarkdownRemark.html }}
                                    />                    
                                    {renderIcons(node.title)}
                                </div>

                                <div className="flex-1 sm:hidden block">
                                    <Img className="w-full h-full mb-4" alt="" fluid={node.picture.fluid} />
                                </div>

                                <div className="flex-col sm:flex-1 justify-between">
                                    
                                <h3 className="font-bold text-gray-800 text-1-5 mb-4">Recent News & Updates</h3>

                                {node.recentNewsAndUpdates && node.recentNewsAndUpdates.map((news) => {
                                    return (
                                        <div className="lg:flex items-start mb-4 sm:mb-12">
                                            <Img className="w-full h-full lg:h-32 lg:w-2/6 mr-6" alt="" fluid={news.picture && news.picture.fluid} />
                                            <div className="lg:w-4/6">
                                                <Link to={(news.slug && `/news/${news.slug}`) || (news.inFocusPdf.file.url || news.pdf.file.url)}>
                                                    <div className="flex-col">
                                                        <div className="inline-block uppercase text-sm text-blue-800 font-bold font-aktivcd">
                                                            {news.__typename === 'ContentfulNewsletters' ? 'Newsletter' : 'Company News'}
                                                        </div>
                                                        <span className="square"></span>
                                                        <span className="text-sm">{news.date}</span>
                                                        <h4 className="font-bold mt-2">{news.title}</h4>
                                                        <p className="hidden md:block text-sm mt-2">{news.description}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                                }

                                </div>
                            </div>

                            <div className="flex-1 px-4 hidden sm:block">
                                <Img className="w-full lg:h-full mb-4" alt="" fluid={node.picture.fluid} />
                            </div>
                            
                        </div>
                    </div>
            )
        } else {
            return (
                <div className="py-8 sm:py-16 mb:8 sm:mb-16">
                        <div className="commitment-anchor" id={node.title.replace(/\s+/g, '').toLowerCase()}/>
                        <div className="container mx-auto sm:flex">

                            <div className="flex-1 px-4">
                                <div className="mb-6">
                                    <h2 className="sm:w-3/4 text-3xl sm:text-5xl font-bold dark-blue leading-tight font-aktiv">{node.title}</h2>
                                    <div className="soft-gray my-4 nested-html"
                                         dangerouslySetInnerHTML={{ __html: node.description.childMarkdownRemark.html }}
                                    />                    
                                    {renderIcons(node.title)}
                                </div>

                                <div className="flex-1 sm:hidden block">
                                    <Img className="w-full h-full mb-4" alt="" fluid={node.picture.fluid} />
                                </div>

                                <div className="flex-col sm:flex-1 justify-between">
                                    
                                    <h3 className="font-bold text-gray-800 text-1-5 mb-4">Recent News & Updates</h3>

                                    {node.recentNewsAndUpdates && node.recentNewsAndUpdates.map((news) => {
                                        return (
                                            <div className="lg:flex items-start mb-4 sm:mb-12">
                                                <Img className="w-full h-full lg:h-32 lg:w-2/6 mr-6" alt="" fluid={news.picture && news.picture.fluid} />
                                                <div className="lg:w-4/6">
                                                    <Link to={(news.slug && `/news/${news.slug}`) || (news.inFocusPdf.file.url || news.pdf.file.url)}>
                                                        <div className="flex-col">
                                                            <div className="inline-block uppercase text-sm text-blue-800 font-bold font-aktivcd">
                                                                {news.__typename === 'ContentfulNewsletters' ? 'Newsletter' : 'Company News'}
                                                            </div>
                                                            <span className="square"></span>
                                                            <span className="text-sm">{news.date}</span>
                                                            <h4 className="font-bold mt-2">{news.title}</h4>
                                                            <p className="hidden md:block text-sm mt-2">{news.description}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </div>

                            <div className="flex-1 px-4 order-first hidden sm:block">
                                <Img className="w-full lg:h-full mb-4" alt="" fluid={node.picture.fluid} />
                            </div>
                            
                        </div>
                    </div>
            )

        }
    })
    }

</Layout>

)};

export const query = graphql`
    query commitments {
       hero: contentfulCommitments {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize (width: 1440) {
                    src 
                }
            }
       }
       intro: contentfulCommitments {
          introductoryImage {
              fluid (maxWidth:800, quality:60) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
       }
      commitments: contentfulCommitments {
          commitments {
              title
              description {
                    childMarkdownRemark {
                        excerpt
                        html
                      }
              }
              picture {
                  fluid (maxWidth:800, quality:60) {
                      ...GatsbyContentfulFluid_withWebp_noBase64
                  }
              }
              recentNewsAndUpdates {
                      __typename
                      ... on ContentfulNews {
                          slug
                          title
                          description
                          date(formatString: "MMMM DD, YYYY")
                          picture { 
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                          }
                      }
                      ... on ContentfulNewsletters {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        pdf {
                            file {
                                url
                            }
                        }
                        inFocusPdf {
                            file {
                                url
                            }
                        }
                        picture: thumbnail { 
                            fluid {
                                ...GatsbyContentfulFluid_withWebp_noBase64
                            }
                        }
                      }
                }
            }
         }
   }
`;


export default Commitments;
